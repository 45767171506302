






































import Vue from "vue";
import _ from "lodash";
import store from "../../store/session";
import * as orderHistory from "../../model/orderHistory";
import { Shop } from "../../model/entity/shop";

export default Vue.component("Check", {
  components: {
    QSkeleton: () => import("../../components/elements/QSkeleton.vue"),
    QCartListTotal: () => import("../../components/elements/QCartListTotal.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    no() {
      return store.state.tableSession.no;
    },
    table_no() {
      return store.state.tableSession.table_no;
    },
    table_name() {
      const langKey = store.state.langKey;
      const staticTranslate = store.state.staticTranslates[langKey] || [];
      if (store.state.tableSession === null) {
        return "";
      }
      var tableTitle = "";
      var no;
      var uketsuke = staticTranslate["hall-0004"];
      if (
        store.state.tableSession &&
        store.state.tableSession.table_seat &&
        store.state.tableSession.table_seat.name
      ) {
        tableTitle = `${store.state.tableSession.table_seat.table_group.name} ${store.state.tableSession.table_seat.name}`;
      } else if (store.state.tableSession.no !== null) {
        no = store.state.tableSession.no;
        tableTitle += `${uketsuke}${no}`;
      }
      return tableTitle;
    },
    shop() {
      return store.state.shop;
    },
    isNaizei(): boolean {
      const shop = store.state.shop as Shop;

      return shop.is_naizei || false;
    },
    summary() {
      const summary = store.state.summary;
      return summary;
    },
  },
  mounted() {
    if (store.state.tableSession.table_session_id === null) {
      return this.$router.push("/error?message=テーブルセッションIDが不明です。");
    }

    this.isLoading = true;

    store.dispatch("fetchTableInfo");
    store
      .dispatch("fetchOrderHistory")
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
  },
  filters: {
  },
});
